import { Env, EnvEnum } from "../lib/environment";
import { PosSystem } from "../lib/pos";

export const getPricingTableFor = (posSystem) => {
  if (Env === EnvEnum.staging) {
    return "prctbl_1QSh7ZFi7AmhG8fbs5Ns18gg";
  }

  if (Env !== EnvEnum.production) {
    return "prctbl_1Ow303EbQwueHA5ZpwgwjoSY";
  }

  switch (posSystem) {
    case PosSystem.LightspeedRetail:
      return "prctbl_1OwPE4EbQwueHA5Ze8WBuoXK";
    case PosSystem.LightspeedXSeries:
      return "prctbl_1OwPBXEbQwueHA5ZIAYmPQ8R";
    case PosSystem.Ascend:
      return "prctbl_1OwP9uEbQwueHA5ZRcM01zea";
    default:
      return "prctbl_1OwAETEbQwueHA5ZY4qTCGes";
  }
};
